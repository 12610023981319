<template>
  <b-container class="dashboad bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <h1>Environment info</h1>

        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="User info" active>
              <h2>User info</h2>
              <ul>
                <li v-for="(value, name) in currentUser" v-bind:key="name">
                  <b>{{ name }}:</b> {{ value }}
                </li>
              </ul>
            </b-tab>
            <b-tab title="COA info">
              <h2>Current chart of accounts:</h2>
              <ul>
                <li v-for="(value, name) in currentCOA" v-bind:key="name">
                  <b>{{ name }}:</b> {{ value }}
                </li>
              </ul>
            </b-tab>

            <b-tab title="Spirecta user stats">
              <h2>Spirecta user stats</h2>
              <b>Transactions</b>
              <ul v-if="chartAccountSatus && Object.prototype.hasOwnProperty.call(chartAccountSatus, 'transactions') && chartAccountSatus.transactions.length > 0">
                <li v-for="(value, name) in chartAccountSatus.transactions[0]" v-bind:key="name">
                  <b>{{ name }}:</b> {{ value }}
                </li>
              </ul>
              <b>Accounts</b>
              <ul>
                <li v-for="(value, name) in chartAccountSatus.accounts" v-bind:key="name">
                  <b>Number of {{ value['type'] }}-accounts:</b> {{ value['number_of_accounts'] }}
                </li>
              </ul>
            </b-tab>
            <b-tab title="Subscription info">
              <h2>Subscription info</h2>
              <ul>
                <li v-for="(value, name) in subscriptionData" v-bind:key="name">
                  <b>{{ name }}:</b> {{ value }}
                </li>
              </ul>
            </b-tab>
            <b-tab title="Stripe subscription info">
              <h2>Stripe subscription info</h2>
              <ul v-if="stripeSubscriptionInfo && Object.keys(stripeSubscriptionInfo).length > 0">
                <li v-for="(value, name) in stripeSubscriptionInfo" v-bind:key="name">
                  <b>{{ name }}:</b> {{ value }}
                </li>
              </ul>
              <p v-else>No subscription information.</p>
            </b-tab>
            <b-tab title="Env. info">
              <ul>
                <li><b>VUE_APP_TITLE: </b>{{ envTitle }}</li>
                <li><b>NODE_ENV: </b>{{ envType }}</li>
                <li><b>VUE_APP_ROOT_API: </b>{{ envRootPath }}</li>
                <li><b>VUE_APP_ROOT_API_EXCL_API_PREFIX: </b>{{ envRootURI }}</li>
              </ul>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'EnvironmentInfo',
  data () {
    return {
      envType: process.env.NODE_ENV,
      envTitle: process.env.VUE_APP_TITLE,
      envRootPath: process.env.VUE_APP_ROOT_API,
      envRootURI: process.env.VUE_APP_ROOT_API_EXCL_API_PREFIX,
      stripeSubscriptionInfo: {},
      chartAccountSatus: {}
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA', 'subscriptionData'])
  },
  mounted () {
    this.stripeSubscriptionInformation()
    this.chartofaccountStatus()
  },
  methods: {
    stripeSubscriptionInformation () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/subscriptions/stripe/info`)
        .then(response => response.data.data)
        .then(response => {
          this.stripeSubscriptionInfo = Array.isArray(response) ? {} : response
        }).catch((error) => {
          console.log(error)
        })
    },
    chartofaccountStatus () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/chart-of-accounts/stats`)
        .then(response => response.data.data)
        .then(response => {
          this.chartAccountSatus = response
        }).catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
